import qs from "qs";

export function appendParams(url = "", params = {}) {
  let oParams;
  const searchIndex = url.indexOf("?");
  if (searchIndex === -1) {
    oParams = {};
  } else {
    oParams = qs.parse(url.slice(searchIndex + 1));
    url = url.slice(0, searchIndex);
  }

  return (
    url +
    "?" +
    qs.stringify({
      ...oParams,
      ...params
    })
  );
}

/**
 * 获取url参数
 * @param  {string} name 参数名
 * @return {string} 参数值
 */
export function getUrlParam(name) {
  const url = window.location.search.split("#")[0];
  const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  const res = url.substr(1).match(reg);
  if (res !== null) {
    return decodeURIComponent(res[2]);
  }
  return null;
}
export function getParams(url) {
  url = url || window.location.href;
  const vars = {};
  let hashes = [];
  if (url.indexOf('?') !== -1) {
    hashes = url.slice(url.indexOf('?') + 1).split('&');
    hashes.map((item) => {
      const key = decodeURIComponent(item.split('=')[0]);
      vars[key] = decodeURIComponent(item.split('=')[1]);
    });
  }
  return vars;
}
/**
 * 将url中的querystring转换为object
 * @param  {String} url 如：a.com/b?c=d&e 或者 a=b&c=
 * @return {Object}     如：{c:'d',e:''}  或者 {a:'b',c:''}
 */
export function parseQuery(url = window.location.search, emptyValue = "") {
  url = url.split("#")[0];
  const arr = url.split("?");
  const queryString = arr[arr.length - 1];
  const entrys = queryString === url ? [] : queryString.split("&");
  const res = {};
  for (let i = 0; i < entrys.length; i++) {
    const entry = entrys[i];
    const arr1 = entry.split("=");

    if (arr1[0]) {
      res[decodeURIComponent(arr1[0])] = decodeURIComponent(
        arr1[1] || emptyValue
      );
    }
  }

  return res;
}
