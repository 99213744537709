<template>
    <div class="video-box" :style="videoBoxStyle">
        <video
            ref="video"
            width="100%"
            height="100%"
            type="video/mp4"
            preload="auto"
            :poster="cover"
            @click="playToggle"
            :src="source"
            x5-playsinline=""
            playsinline=""
            webkit-playsinline=""
        ></video>
        <img class="video-play-cover" v-if="cover && !played" :src="cover" type="image/webp" @click="playVideo" />
        <span class="video-play-btn" v-show="!playing" @click="playVideo"></span>
    </div>
</template>

<script>
export default {
    data() {
        return {
            played: false,
            playing: false,
            isFull: false,
        };
    },

    props: {
        source: {
            type: String,
            required: true,
        },
        width: {
            type: [String, Number],
            default: '100%',
        },
        height: {
            type: [String, Number],
            default: '0',
        },
        cover: {
            type: String,
            required: true,
        },
    },

    computed: {
        videoBoxStyle() {
            return {
                width: this.width !=='100%' ? this.width + 'px' : '100%',
                height: this.height !=='0' ? this.height + 'px' : 'auto',
                fontSize: 0,
            };
        },

        video() {
            return this.$refs.video;
        },
    },

    methods: {
        playToggle() {
            if (this.playing) {
                this.pauseVideo();
            } else {
                this.playVideo();
            }
        },

        pauseVideo() {
            this.video.pause();
            this.playing = false;
            this.$weblog.collect('CLICK', 'model_share_video_pause', { id: this.$parent.id });
        },

        playVideo() {
            this.playing = true;
            this.video.play();
            this.$weblog.collect('CLICK', 'model_share_video_play', { id: this.$parent.id });
        },

        playVideoEnd() {
            this.played = false;
            this.playing = false;
            this.$weblog.collect('CLICK', 'model_share_video_playend', { id: this.$parent.id });
        },
        timeUpdate(event) {
            const video = event.srcElement;
            if (video.currentTime > 0) {
                this.played = true;
            }
        },
    },

    mounted() {
        this.video.addEventListener('ended', this.playVideoEnd);
        this.video.addEventListener('timeupdate', this.timeUpdate, false);
        this.video.addEventListener('x5videoexitfullscreen', () => {
            if (this.playing) {
                this.video.play();
            }
        });
    },
};
</script>

<style lang="less" scoped>
.video-box {
    position: relative;
    flex: 1;
}
.video-play-btn {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    width: 1.32rem;
    height: 0.94rem;
    background-image: url('https://ali-ky.static.yximgs.com/udata/pkg/kmovie/fontend-activity/video-play-btn.6ea6f4628f761a29.png');
    background-size: 100% 100%;
    display: inline-block;
    z-index: 1;
    opacity: 1;
}
.video-play-cover {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: contain;
}
</style>
