import crypto from './crypto';
import axios from 'axios';

// get请求
export default function get(url, query = {}) {
    const baseUrlMap = process.env.KyApi;
    const HOST = window.location.hostname.indexOf('kittyfunny.com') > -1 ? "https://g-api.kittyfunny.com" : (baseUrlMap.HOST || 'https://api.kmovie.gifshow.com');
    console.log('baseUrlMap==', HOST);
    return axios({
      method: 'get',
      url: `${HOST}${url}`,
      params: {
        ...query,
        ...crypto('yiuhjkbvhbjisjchgdnx38uejd'),
      },
    });
  }