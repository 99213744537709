/* eslint-disable */
import axios from 'axios';
import weblog from './log';
import { appName } from './device';

axios.defaults.timeout = 5000;
axios.defaults.withCredentials = true;

axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        return Promise.reject(error);
    }
);

export const isWexin = appName === 'wechat';
// 回调队列,预存wx未ready前的调用，ready后执行并置为 null,之后直接执行回调函数
let queue = [];

export async function wxInit() {
    if (!isWexin) {
        return;
    }
    const { data } = await axios({
        method: 'post',
        url: '//tangyuan-api.kwaiying.com/rest/n/kmovie/wx/gzh/js/kyjj/getSignature',
        data: {
            url: location.href,
        },
        headers: { 'Content-Type': 'application/json' },
    });

    if (data.result === 1) {
        wx.config({
            debug: process.env.NODE_ENV === 'development',
            appId: 'wx47066ee756824c62',
            timestamp: data.timestamp,
            nonceStr: data.noncestr,
            signature: data.signature,
            jsApiList: [
                'updateTimelineShareData', // 分享到朋友圈、分享到QQ空间
                'updateAppMessageShareData', // 分享给朋友、分享到QQ
                'onMenuShareWeibo', // 分享到腾讯微博
            ],
            openTagList: ['wx-open-launch-app'],
        });
        wx.ready(function() {
            // 有可能会被 ready 2次
            if (!queue) {
                return;
            }
            for (let i = 0; i < queue.length; i++) {
                queue[i](wx);
            }
            queue = null;
        });
    }
}

let inited = false;
export function wxReady(fn) {
    if (!isWexin) {
        return;
    }
    if (typeof fn !== 'function') {
        throw new Error('wxReady argument is a function');
    }
    if (!inited) {
        wxInit();
        inited = true;
    }
    if (queue) {
        queue.push(fn);
    } else {
        fn(wx);
    }
}

export function wxShareConfig(share, pageTag) {
    if (!isWexin) {
        return;
    }
    if (!share) {
        throw new Error('wxShareAsync arguments error')
    }

    pageTag = pageTag || '';
    // var extlog = share.extlog;
    delete share.extlog;

    wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
        wx.updateAppMessageShareData({ 
            title: share.title || '快影', // 分享标题
            desc: share.desc || '给你分享了一个好玩的模板，快来体验！', // 分享描述
            link: share.link || location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: share.imgUrl || 'https://static.yximgs.com/udata/pkg/kuaiying-frontend-cdn/kuaiying-frontend-csr/kuaiying.ico', // 分享图标
            success: function() {
            // 设置成功
                weblog.collect('CLICK', pageTag, { share: 'success', templateId: this.id });
            },
            error: function(e) {
                weblog.collect('CLICK', pageTag, { share: 'error', errorMsg: e, templateId: this.id });
            }
        })
    }); 
}

