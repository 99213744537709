export function storage(key, val) {
  const argsLength = arguments.length;
  try {
    if (argsLength === 1) {
      // 读取数据
      const valStr = localStorage && localStorage.getItem(key);
      return JSON.parse(valStr);
    }
    // 变更数据
    localStorage && localStorage.setItem(key, JSON.stringify(val));
  } catch (err) {
    return {};
  }
}

// 不完整的模拟node util.format
// http://git.corp.kuaishou.com/ks/kuaishou-frontend/blob/master/www/mobile/src/static/js/lib/util.js#L218
function _format() {
  const target = arguments[0];
  if (!target || typeof target !== "string") {
    return target;
  }
  const replacer = [].splice.call(arguments, 1);
  let index = 0;
  return target.replace(/(%s)/g, function() {
    // eslint-disable-next-line
        return replacer[index] !== undefined ? replacer[index++] : arguments[0];
  });
}

/**
 * 格式化处理 ksnode-language 字符串中的 %s, ${0} 等占位符
 * 自定义格式化函数, 为什么要自定义？因为有时候字符串里的替换符是%s, 有的时候是${0}, ${0}, 在此统一处理一下
 *
 * @param      {Array}   args[0]: 要格式化的字符串， args[1] ... args[n] 待替换的变量
 * @return     {string}  { 格式化后的字符串 }
 */
export function ksLanguageFormat(...args) {
  let targetStr = args[0];

  if (!targetStr) {
    return "";
  }

  if (-1 !== targetStr.indexOf("%s")) {
    targetStr = _format.apply(null, args);
  }

  for (let i = 1; i < args.length; i++) {
    const replacement = "${" + (i - 1) + "}";
    if (-1 !== targetStr.indexOf(replacement)) {
      // args[1] 对应的是 ${0}, args[2] 对应的是 ${1}, 依次类推
      // 把所有${0}全部替换为args[1]，依次类推
      targetStr = targetStr.split(replacement).join(args[i]);
    }
  }

  return targetStr;
}

export function numberFloorWan(count) {
  if (count > 9999) {
    // 保留一位就是 10 两位就是100
    count = Math.floor((count / 10000) * 10) / 10 + "万";
  }
  return count;
}

export function getQuery(name) {
  const search = location.search;
  if (!name || !search) {
    return;
  }
  const result = search.match(new RegExp(`[?&]${name}=([^&]+)`, "i"));
  if (result !== null && result.length > 0) {
    // eslint-disable-next-line consistent-return
    return result[1];
  }
}

/**
 * 固定数字位数为两位
 *
 * @param  {number} num 处理前的数字
 * @return {string}  { 格式化后的字符串 }
 */
export function toFixed(num) {
  return num < 10 ? "0" + num : num;
}

/**
 * 返回本地时间，单位秒
 *
 * @return {string}  { 返回本地时间 }
 */
export function getLocalTime() {
  return parseInt(new Date().getTime() / 1000, 10);
}

/**
 * 程序延迟time ms
 *
 * @param  {number} time 需要延迟的时间 单位是ms
 */
export function sleep(time) {
  return new Promise(resolve => {
    setTimeout(resolve, time);
  });
}

/**
 * 产生min到max的随机时间,向下舍入
 *
 * @return {number}  { min到max的随机时间 }
 */
export function random(min = 0, max) {
  return Math.floor(Math.random() * (max - min) + min);
}
