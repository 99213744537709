<template>
    <div
        v-if='openUrl'
        :openUrl='openUrl'
        :logType='logType'
        :id='id'
        :shareKey='shareKey'
        :wxShareKey='wxShareKey'
    >
        <template v-if="isWexin && wxInitSuccess">
            <div class="wx-open-launch-app-block">
                <div class="slot-wrap">
                    <slot></slot>
                </div>
                <wx-open-launch-app
                    class="launch-btn"
                    :appid="appId"
                    :extinfo="openUrl"
                    @error="handleErrorFn"
                    @launch="handleLaunchFn"
                >
                    <script type="text/wxtag-template">
                        <div style="padding: 1000px; opacity: 0;"></div>
                    </script>
                </wx-open-launch-app>
            </div>
        </template>
        <div v-else  @click="openKy(logType)">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import { isWexin } from '@/utils/wx';
import open from '@ky/sdk/dist/open';
import kylogger from '@ky/sdk/dist/log/kylogger';
import wxSDK from '@ky/sdk/dist/wxSDK'; 
export default {
    props: {
        openUrl: {
            type: String,
            default: '',
        },
        logType: {
            type: String,
            default: '',
        },
        id: {
            type: String,
            default: '',
        },
        shareKey: {
            type: String,
            default: '',
        },
        wxShareKey: {
            type: String,
            default: '',
        },
    },
    components: {
    },
    data() {
        return {
            appId: 'wxb0a1224c62ee23b3', // 快影
            isWexin:isWexin,
            wxInitSuccess: true,
        };
    },
    methods: {
        openKy(type) {
            kylogger.collect('CLICK',this.shareKey,{
                btn_name:type,
                id:this.id,
                mv_id:this.id
            })
            open(this.openUrl)
        },
        wxOpenHandler(type, e) {
            kylogger.collect('CLICK',this.wxShareKey,{
                type,
                btn_name:type,
                id:this.id,
                mv_id:this.id,
                pageTag: this.pageTag,
                msg: JSON.stringify(e)
            })
            if (e?.detail?.errMsg === "launch:fail") {
                open(this.openUrl)
            }
        },
        // 监听launch 函数，用户点击跳转按钮并对确认弹窗进行操作后触发
        handleLaunchFn(e) {
            console.log('handleLaunchFn:', e);
            this.wxOpenHandler('launch', e);
        },
        // 用户点击跳转按钮后出现错误
        handleErrorFn(e) {
            console.log('handleErrorFn:', e);
            this.openKy(this.openUrl)
        },
    },
    async created() {
        if (this.isWexin) {
           try {
                await wxSDK.init();
            } catch (err) {
                // 初始化失败-非卡片形式
                this.wxInitSuccess = false
            }
        }
    }
  }
</script>

<style scoped lang="less">
.wx-open-launch-app-block {
    display: block;
    position: relative;
    overflow: hidden;
    .slot-wrap {
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .launch-btn {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 99;
    }
}
</style>
