/**
 * @file 笑番接口加密过程
 *
 * 统一使用commonjs规范，适配nodejs端使用。
 */
import md5 from 'blueimp-md5';

/**
 * 或运算操作，适配32位以上int
 *
 * @param {number} a
 * @param {number} b
 * @return {number}
 */
function or(a, b) {
    a = a
        .toString(2)
        .split('')
        .reverse();
    b = b
        .toString(2)
        .split('')
        .reverse();
    const L = Math.max(a.length, b.length);
    const re = [];
    for (let i = 0; i < L; i++) {
        re.push(+a[i] || +b[i] ? 1 : 0);
    }

    return parseInt(re.reverse().join(''), 2);
}

/**
 * 异或运算，适配32位以上int
 *
 * @param {*} a
 * @param {*} b
 * @return {number}
 */
function xor(a, b) {
    a = a
        .toString(2)
        .split('')
        .reverse();
    b = b
        .toString(2)
        .split('')
        .reverse();
    const L = Math.max(a.length, b.length);
    const re = [];
    for (let i = 0; i < L; i++) {
        // eslint-disable-next-line no-nested-ternary
        re.push(a[i] && b[i] ? (a[i] !== b[i] ? 1 : 0) : a[i] || b[i]);
    }
    return parseInt(re.reverse().join(''), 2);
}

/**
 * 运算过程1
 *
 * @return {number}
 */
function q(s) {
    return +s
        .split('')
        .map((val) => val.charCodeAt().toString(16))
        .join('')
        .replace(/[a-f]/gi, '')
        .substr(0, 16);
}

/**
 * 运算过程2
 *
 * @param {number} s
 * @param {number} t
 * @return {number}
 */
function m(s, t) {
    return or(xor(s, t), s);
}

/**
 * 运算过程3
 *
 * @return {string}
 */
function n(a, b) {
    return md5(xor(a, b));
}

/**
 * 创建指定位数随机数
 *
 * @param {number} 随机数长度
 * @return {number} 随机数
 */
function createUuid(len) {
    let v = Math.random()
        .toString()
        .slice(-len);
    // 避免首位为0
    if (v[0] === '0') {
        v = '1' + v.slice(1);
    }
    return +v;
}

/**
 * 加密入口函数
 *
 * @param {boolean} isString
 * @return {string|object} 函数
 */
function crypto(s, isString = false) {
    const timestamp = +new Date();
    const nonce = createUuid(12);
    const a = q(s);
    const b = m(a, timestamp);
    const sign = n(+nonce, b);

    return isString
        ? `timestamp=${timestamp}&nonce=${nonce}&sign=${sign}`
        : {
              timestamp,
              nonce,
              sign,
          };
}

/**
 * @exports {function}
 */
export default crypto;
