import Locale, { t } from '@ky/locale/dist/index';
import getDeviceInfoSync from '@ky/sdk/dist/getDeviceInfoSync';
import { getQuery } from './utils';
import Vue from 'vue';

const deviceInfo = getDeviceInfoSync();

console.log('deviceInfo', deviceInfo);
const defaultLang = deviceInfo.isOverseas ? 'en-us' : 'zh-cn';

const paramLang = getQuery('lang');
const language =
    paramLang || deviceInfo.language || defaultLang;


    console.log('Locale.lang', Locale, t);
Locale.set(language);


const lang = Vue.observable({ value: Locale.lang });

Locale.onLangChange((newLang) => {
    lang.value = newLang;
});

Locale.install = function(vue) {
    vue.prototype.$Locale = Locale;
    vue.prototype.$t = (...args) => {
        return t(lang.value, '', ...args)
    };
};

export default Locale;
