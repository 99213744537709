import axios from "axios";

const baseUrlMap = process.env;
axios.defaults.timeout = 100000;
axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  response => {
    return response && response.data;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.request.use(config => {
  const { url } = config;
  const [hostKey, path] = url.split("::");
  const project = baseUrlMap[hostKey];
  if (project) {
    const host = window.location.hostname.indexOf('kittyfunny.com') > -1 ? "https://g-api.kittyfunny.com" : project.HOST;
    config.url = `${host}/${path}`;
  } else {
    throw new Error("未配置的请求Key，请在Config中添加");
  }
  return config;
});

export default axios;
