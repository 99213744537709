let count = 0;

export function callNative(name, params, isPromise = true) {
  if (window.kwaiying && window.kwaiying[name]) {
    if (!params && !isPromise) {
        window.kwaiying[name]();
    } else {
        params = params || {};
        if (isPromise) {
          return new Promise((resolve) => {
            const callbackName = `__bridge__${count++}__`;
            params.callback = callbackName;
            window[callbackName] = (data) => {
              console.log('qqqq', data);
              let result = data && data.result ? data.result : data;
              resolve(result)
              delete window[callbackName];
            }
            window.kwaiying[name](JSON.stringify(params));
          })
        } else {
          window.kwaiying[name](JSON.stringify(params));
        }
    }
  } else {
    return Promise.reject(new Error(`method: ${name} not found`));
  }
}

export function getEnv() {
  return callNative('getInfoFromKwaiYing', {infoName: 'envConfig'}).then(env => JSON.parse(env)).catch(() => {
    return {"type":2,"url":"https://api.kuai-ying.com/","direct":0};
  })
}