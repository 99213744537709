// toast
export default function toast(text) {
    if (!text) {
        return;
    }
    const id = `toast_${Math.round(Math.random() * 1000000)}`
    const d = document.createElement('div');
    d.id = id;
    d.innerHTML = `<div style="text-align: center;max-width: 65%;border-radius: 0.44rem; padding: 0.18rem 0.43rem; line-height: 0.48rem; background: rgba(34,34, 34,0.9);">${text}</div>`;
    d.style.cssText = `
        z-index: 99999;
        font-size: 0.26rem;
        position: fixed;
        top: 300px;
        left: 0;
        right: 0;
        color: #fff;
        display: flex;
        justify-content: center;
        animation: 0.12s ease-out forwards showToast;
    `;
    document.getElementsByTagName('body')[0].appendChild(d);
    setTimeout(() => {
        const removeEle = document.getElementById(id);
        removeEle.style.cssText = `
            ${removeEle.style.cssText}
            animation: 0.12s ease-in forwards hideToast;
        `;
        setTimeout(() => {
            document.body.removeChild(removeEle);
        }, 150)
    }, 5000);
}
