/* eslint-disable */
/**
 * cookie操作库
 */


export function getCookie(objName) {
    // 获取指定名称的cookie的值
    var arrStr = document.cookie.split('; ');
    var temp;
    for (var i = 0; i < arrStr.length; i++) {
        temp = arrStr[i].split('=');
        if (temp[0] == objName) {
            return unescape(temp[1] || '');
        }
    }
    return '';
}
// 设置cookie, 如setCookie('abc', 'value', {
//   path: '/',
//   expires: 3, // expires 当为数字时单位为天, 也可以传UTC string, 默认为session级别
//   httpOnly: false
//   secure: false
//   domain: 'xxx'
// })
// refer from: https://github.com/js-cookie/js-cookie
export function setCookie(key, value, attributes) {
    attributes = extend({
        path: '/'
    }, attributes);

    if (typeof attributes.expires === 'number') {
        var expires = new Date();
        expires.setMilliseconds(expires.getMilliseconds() + attributes.expires * 864e+5);
        attributes.expires = expires;
    }

    // We're using "expires" because "max-age" is not supported by IE
    attributes.expires = attributes.expires ? attributes.expires.toUTCString() : '';

    value = encodeURIComponent(String(value))
        .replace(/%(23|24|26|2B|3A|3C|3E|3D|2F|3F|40|5B|5D|5E|60|7B|7D|7C)/g, decodeURIComponent);

    key = encodeURIComponent(String(key));
    key = key.replace(/%(23|24|26|2B|5E|60|7C)/g, decodeURIComponent);
    key = key.replace(/[\(\)]/g, escape);

    var stringifiedAttributes = '';

    for (var attributeName in attributes) {
        if (!attributes[attributeName]) {
            continue;
        }
        stringifiedAttributes += '; ' + attributeName;
        if (attributes[attributeName] === true) {
            continue;
        }
        stringifiedAttributes += '=' + attributes[attributeName];
    }
    document.cookie = key + '=' + value + stringifiedAttributes;
}

