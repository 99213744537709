import get from './get';
// pc,ios,android
export const osName = (function () {
  const ua = navigator.userAgent;
  const rules = {
    // 平板  暂时只处理ipad
    // pad: /\biPad\b/,
    android: /\bAndroid\b/,
    ios: /\b(iPad|iPhone)\b/
  };
  function getOSName() {
    for (const key in rules) {
      const rule = rules[key];
      if (rule.test(ua)) {
        return key;
      }
    }
    return "pc";
  }
  return getOSName();
})();

/**
 * 当前所在 app名
 * 'wechat' || 'kwai' || 'qq' ...
 */
export const appName = (function () {
  // 方便测试
  if (localStorage.getItem("debug_mock_kwai")) {
    return "kwai";
  }
  const ua = navigator.userAgent;
  const appRules = {
    qq: /\bQQ\b/,
    // 快手app
    kwai: / (Kwai|Kwai_Lite|Kwai_Pro|livemate|ksthanos|ksNebula|kswv)\//,
    wechat: /\bMicroMessenger\b/,
    weibo: /\bWeibo\b/,
    qzone: /\bQzone\b/,
    // 腾讯浏览服务(Tencent Browser Service) 简称TBS
    // tbs: /\bTBS\b/,
    // 手机QQ浏览器
    // qqBrowser: /\bMQQBrowser\b/,
    // 手机百度
    baidu: /\bbaiduboxapp\b/,
    uc: /\bUCBrowser\b/
  };
  /**
   * 获取当前所在 app名
   * return 'wechat' || 'kwai' || 'qq' ...
   */
  function getAppName() {
    for (const key in appRules) {
      const rule = appRules[key];
      if (rule.test(ua)) {
        return key;
      }
    }
    return "UNKNOWN";
  }
  return getAppName();
})();

/**
 * 是否在快手
 * 可以被moke localStorage debug_mock_kwai
 */
export const isInKwai = appName === "kwai";

export const isRealInKwai = !!window.Kwai;

export function inIPhoneX() {
  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const ratio = window.devicePixelRatio || 1;
  const screen = {
    width: window.screen.width * ratio,
    height: window.screen.height * ratio
  };
  return iOS && screen.width === 1125 && screen.height === 2436;
}

// 是否在极速版中
export function inKsNebula() {
  return /\sksNebula\//.test(navigator.userAgent);
}

/**
 * 是否在快影端内
 */
export function inKwaiying() {
  return /\kwaiying\//.test(navigator.userAgent);
}

/**
 * 检测运行浏览器环境, 支持判断qq内置浏览器环境，微信环境。
 *
 * @param {string} ua 浏览器ua
 * @return {object} env 判断结果
 * @return {string} env.brower 浏览器环境 wx | qq | other
 * @return {string} env.platform 设备环境 IOS | Android | other
 */
 export function getDeviceEnv(ua) {
    const env = {
        brower: '',
        platform: '',
    };

    // 检测浏览器环境
    if (/MicroMessenger/i.test(ua)) {
        env.brower = 'wx';
        // eslint-disable-next-line no-else-return
    } else if (/ QQ\//i.test(ua)) {
        env.brower = 'qq';
    } else {
        env.brower = 'other';
    }

    // 检测平台环境
    if (/iP(hone|ad)/.test(ua)) {
        env.platform = 'IOS';
    } else if (/Android/.test(ua)) {
        env.platform = 'Android';
    } else if (/Windows/.test(ua)) {
        env.platform = 'Windows';
    } else {
        env.platform = 'other';
    }

    return env;
}

// 获取下载快影的url
export function downloadKYUrl() {
    const os = getDeviceEnv('platform');
    const isMobile = /Mobile/i.test(navigator.userAgent);
    const isWeChat = /MicroMessenger/i.test(navigator.userAgent);
    const iOSDownloadUrl = 'https://itunes.apple.com/cn/app/id1195860596';
    const AndroidDownloadUrl = isMobile
        ? 'market://details?id=com.kwai.videoeditor'
        : 'https://www.kuaishou.com/kuaiying';
    // 应用宝下载地址
    const yingyongbaoDownloadUrl = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.kwai.videoeditor';
    // eslint-disable-next-line no-nested-ternary
    return isWeChat ? yingyongbaoDownloadUrl : os === 'IOS' ? iOSDownloadUrl : AndroidDownloadUrl;
}


/**
 * 下载或者唤醒快影app
 * TODO: safari唤醒存在bug
 *
 * @return {promise}
 */
export function openOrDownloadKwaiying(schema, delay = 2000) {
    const ua = navigator.userAgent;
    const device = getDeviceEnv(ua);
    const isWX = device.brower === 'wx';
    const isQQ = device.brower === 'qq';
    const isIOS = device.platform === 'IOS';
    const isAndroid = device.platform === 'Android';
    const links = {
        IOS: 'https://itunes.apple.com/cn/app/id1195860596',
        // 实时获取
        Android: '',
        // 应用宝下载链接
        yingyongbao: `https://a.app.qq.com/o/simple.jsp?pkgname=com.kwai.videoeditor${
            schema ? '&android_schema=' + encodeURIComponent(schema) : ''
        }`,
        Windows: 'https://www.kuaishou.com/kuaiying',
    };
    const getUlink = (schema) => {
      if (!isIOS) {
        return schema;
      }
      return 'https://ky.kuaishou.com/schema?' + encodeURIComponent(schema);
    }
    // 异步发送，避免log发送丢失
    setTimeout(() => {
        // ios qq/wx环境直接跳商店,无法唤醒
        if (isIOS && (isWX || isQQ)) {
            window.location.href = links.IOS;
            // android wx环境进入应用宝，无法唤醒
        } else if (isAndroid && isWX) {
            window.location.href = links.yingyongbao;
        } else {
            let url = schema;
            if (schema && typeof schema === 'string') {
              url = getUlink(schema);
            } else {
              url = getUlink(isIOS ? 'kwaiying://' : 'kwaiying://main');
            }
            let iframe;
            if (isIOS) {
                window.location.href = url;
                return;
            } else {
                iframe = document.createElement('iframe');
                iframe.width = 0;
                iframe.height = 0;
                iframe.style.cssText = 'position:absolute;top:-9999px;left:-9999px';
                iframe.src = url;
                document.body.appendChild(iframe);
            }
            // 某些浏览器下，location前置请求导致跳转无效，如小米原生浏览器。
            setTimeout(async () => {
              iframe && document.body.removeChild(iframe);
              if (document.hidden) {
                return;
              }
              if (device.platform === 'Android' && !isWX && !isQQ) {
                  try {
                      const { data } = await get('/rest/n/kmovie/app/h5/share/download/getUrl');
                      const { result, url } = data;
                      result === 1 && (links.Android = url);
                  } catch (e) {
                      // error 时，默认采用windows下载地址
                  }
              }
              window.location.href = isQQ ? links.yingyongbao : links[device.platform] || links.Windows;
            }, delay);
        }
    }, 500);
}

